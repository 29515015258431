import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Піцерія Старе Місто - Справжня італійська піца у вашому місті
			</title>
			<meta name={"description"} content={"Насолоджуйтесь справжньою італійською піцею та іншими смачними стравами у затишній атмосфері."} />
			<meta property={"og:title"} content={"Піцерія Старе Місто - Справжня італійська піца у вашому місті"} />
			<meta property={"og:description"} content={"Насолоджуйтесь справжньою італійською піцею та іншими смачними стравами у затишній атмосфері."} />
			<meta property={"og:image"} content={"https://earthexplorershub.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://earthexplorershub.com/img/6635075.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://earthexplorershub.com/img/6635075.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://earthexplorershub.com/img/6635075.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://earthexplorershub.com/img/6635075.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://earthexplorershub.com/img/6635075.png"} />
			<meta name={"msapplication-TileImage"} content={"https://earthexplorershub.com/img/6635075.png"} />
			<meta name={"msapplication-TileColor"} content={"https://earthexplorershub.com/img/6635075.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Description-9">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				align-items="flex-start"
				lg-align-items="center"
				flex-direction="column"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				justify-content="flex-start"
				display="flex"
				width="50%"
			>
				<Box>
					<Text
						margin="0px 0px 36px 0px"
						color="--darkL2"
						font="--headline1"
						lg-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Про компанію
					</Text>
				</Box>
				<Text margin="0px 0px 0px 0px" color="--grey" font="--lead" lg-text-align="center">
				Піцерія "Старе Місто" відкрила свої двері у 2015 році з метою привнести автентичний смак італійської піци у наше місто. За цей час ми стали улюбленим місцем для багатьох жителів та гостей міста. Ми прагнемо зберегти традиції італійської кухні та передати їх нашим гостям у кожній страві.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-y="hidden"
					padding="0px 0px 100% 0px"
					overflow-x="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						object-fit="cover"
						display="block"
						width="100%"
						right={0}
						src="https://earthexplorershub.com/img/4.jpg"
						position="absolute"
						top="auto"
						left={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Description-8" background="--color-primary">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
			>
				<Box
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					width="100%"
				>
					<Image
						position="absolute"
						display="block"
						top="auto"
						left={0}
						right={0}
						bottom="0px"
						min-height="100%"
						src="https://earthexplorershub.com/img/5.jpg"
						object-fit="cover"
						width="100%"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="50%"
				lg-order="1"
				justify-content="flex-start"
				align-items="flex-start"
				padding="24px 24px 24px 24px"
			>
				<Box>
					<Text
						margin="0px 0px 36px 0px"
						color="--light"
						font="--headline2"
						lg-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Наша команда
					</Text>
				</Box>
				<Text margin="0px 0px 0px 0px" color="--light" font="--lead" lg-text-align="center">
				У "Старе Місто" працює команда професіоналів, які люблять свою справу. Наші кухарі - справжні майстри кулінарії, які постійно вдосконалюють свої навички та експериментують з новими рецептами. Наш персонал завжди готовий допомогти вам з вибором страв та забезпечити високий рівень обслуговування.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h2" font="--headline2" md-font="--headline2" margin="20px 0 0 0">
			Наші досягнення
			</Text>
			<Text as="p" font="--lead" margin="20px 80px" sm-margin="20px 10px" text-align="center">
			За роки роботи ми отримали багато позитивних відгуків та нагород. Ми пишаємося тим, що стали улюбленим місцем для багатьох жителів міста. Ваші рекомендації та підтримка - найкраща нагорода для нас. Ми обіцяємо продовжувати радувати вас смачними стравами та відмінним сервісом.
			</Text>
			<Button
				font="--lead"
				margin="20px"
				type="link"
				text-decoration-line="initial"
				href="/contact"
			>
				Відвідайте нас
			</Button>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"652f9cc5dbe26f0020fd00a7"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});